import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/_loom": [5],
		"/_prototype": [6],
		"/account": [7],
		"/admin": [8],
		"/admin/email": [9],
		"/login": [10],
		"/pricing": [11],
		"/pricing/success": [12],
		"/projects": [13,[2]],
		"/projects/[projectId]": [14,[2,3]],
		"/projects/[projectId]/assets": [15,[2,3]],
		"/projects/[projectId]/new-request": [16,[2,3]],
		"/projects/[projectId]/requests/[id]": [17,[2,3]],
		"/projects/[projectId]/requests/[id]/edit": [18,[2,3]],
		"/register": [19],
		"/requests": [20],
		"/scope": [21],
		"/sentry-example": [22],
		"/talent": [23],
		"/talent/[id]": [24],
		"/test": [25],
		"/vime": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';